<template>
  <div class="pb-5">
    <div class="d-flex justify-content-center col-12 text-center">
      <head-banner />
    </div>
    <div class="d-flex justify-content-center col-12 text-center">
      <add-here />
    </div>
    <b-row>
      <b-col>
        <!-- <h2>Why Promote With Us?</h2> -->
        <h3>1. Banner Ads (Discount prices)</h3>
        <h3 class="pl-1">Rotational banner ad 150*390</h3>
        <li>One day promotion - 80$</li>
        <li>10 days promotion - 750$</li>
        <li>One month promotion - 2000$</li>

        <h3 class="pt-2 pl-1">
          Top banner ad 1022*115 (This will be visible to every individual page)
        </h3>
        <li>One day promotion - 150$</li>
        <li>10 days promotion - 1300$</li>
        <li>One month promotion - 3250$</li>

        <h3 class="pl-1">Pop up banner ads</h3>
        <li>One day promotion - 350$</li>
        <li>10 days promotion - 3250$</li>
        <li>One month promotion - 9500$</li>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="pt-2">2. Top promotion Listing (Discount prices)</h3>
        <li>1-day promotion 70$</li>
        <li>10 days promotion 600$</li>
        <li>One month promotion 1500$</li>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="pt-2">
          3. Coin Audits, Code testing, and Market feasibility study (Discount prices)
        </h3>
        <li>
          500$ to 1500$
          <b-badge variant="dark"
            >(The price will vary based on the complexity of the code)</b-badge
          >
        </li>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-3">
        <h4 class="pt-2">Privileges you will get</h4>
        <li>Your token will be listed under the audit section forever</li>
        <li>
          We will direct all the visitors inquiring about legit coins through our live
          chat to your project
        </li>
        <li>We will make the report public on GitHub</li>
        <li>
          We will make a public announcement through Twitter when we have enough
          followers.
        </li>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="pt-2">4. Smart contract development</h3>
        <li>1250$ onwards</li>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="pt-2">5. Dapp development</h3>
        <li>2500$ onwards</li>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="pt-2">6. Video creation with all graphics and voicing</h3>
        <li>2 mins video 750$</li>
        <li>5 mins video 1250$</li>
        <li>10 mins video 2250$</li>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="pt-2">
          7. Email marketing campaign targeting 30,000 unique crypto investors. (Only 1
          campaign will be executed per day to keep the results effective)
        </h3>
        <li>
          1000$ (We will execute the full campaign and create the HTML template, a report
          will be provided at the end of the campaign with results)
        </li>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="pt-2">8. Web development</h3>
        <li>800$ onwards</li>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="pt-2">9. Mobile app/ systems/ IoT or any software solution</h3>
        <li>2500$ onwards</li>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="pt-2">10. Company registration</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" class="pl-3">
        <h4 class="pt-2">i ) UK company registration</h4>
        <p>The basic package (600$)</p>
        <span class="span-class">1. Online Company formation </span>
        <span class="span-class">2. Company registered address for 1 year </span>
        <span class="span-class">3. HMRC Registration </span>
        <span class="span-class">4. Email copy of the certificate </span>
        <span class="span-class">5. One UK Sim card </span>

        <p class="pt-2">Additional Services</p>
        <span class="span-class">1. Certified Documents: 250$ </span>
        <span class="span-class">2. UK Register of Learning Providers: 600$ </span>
        <span class="span-class">3. ICO Registration: 350$ </span>
        <span class="span-class"
          >4. Business service and Director Service Address: 350$ / per year
        </span>
        <span class="span-class">5. UK Bank Account Opening: 350$ </span>
      </b-col>
      <b-col md="6" class="pl-3">
        <h4 class="pt-2">ii ) USA Company Registration (Kentucky State)</h4>
        <p>The basic package (850$)</p>
        <span class="span-class">1. Online Company Formation </span>
        <span class="span-class">2. 1 Year registered agent service </span>
        <span class="span-class"
          >3. Lifetime USA business address and personal address in Texas
        </span>
        <span class="span-class">4. Get the EIN Number for the company </span>
        <span class="span-class">5. Email Copy of the certificate </span>

        <p class="pt-2">Additional Services</p>
        <span class="span-class">1. Certified Documents: 250$ </span>
        <span class="span-class">2. USA SIM Card: 50$ </span>
        <span class="span-class">3. Business Formation Kit: 350$ </span>
        <span class="span-class">4. USA Business Bank Account Opening: 350$ </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mixinList } from "@/mixins/mixinList";
import { BRow, BCol, BBadge } from "bootstrap-vue";
import AddHere from "@/@core/components/AddHere.vue";
import HeadBanner from "@/@core/components/carousel/HeadBanner.vue";

export default {
  mixins: [mixinList],
  components: {
    BRow,
    BCol,
    BBadge,
    AddHere,
    HeadBanner,
    // BImg,
  },
};
</script>
<style>
.span-class {
  display: flex;
  margin-left: 20px;
}
/* li {
  margin-left: 20px;
} */
</style>
